<template>
  <div class="faq">
    <div class="faq__list">
      <b-collapse
        class="faq__list-item"
        v-for="(qustion, index) in faqList"
        :key="index"
        :open="isOpen == index"
        @open="isOpen = index"
      >
        <div class="faq__container" slot="trigger" slot-scope="props" role="button">
          <img class="faq__icon" src="@/assets/img/faq.png" alt>
          <h3 class="card-header-title">{{ qustion.title }}</h3>
        </div>

        <p class="content" v-html="qustion.question"></p>
      </b-collapse>
    </div>
    <!-- <ul class="faq__list">
      <li 
        v-for="(qustion, index) in faqList" 
        :key="qustion.id"
        @click="item(index)"
        class="faq__list-item">
        <h3>{{ qustion.title }}</h3>
        <p>{{ qustion.question }}</p>
      </li>
    </ul>-->
  </div>
</template>

<script>
export default {
  name: "FAQPage",
  data: () => ({
    isOpen: 0,
    faqList: [
      {
        id: 1,
        title: `1. Do you have test/free numbers?`,
        question: `No. We provide only paid numbers. Because we have only real SIM cards.`
      },
      {
        id: 2,
        title: `2. I sent SMS to number, but I do not see it in my cabinet, what should I do?`,
        question: `If SMS for some reason does not come, the problem is in the service that you are trying to verify, or from other reasons beyond our control. If you are waiting for SMS and do not receive, you can send SMS from your number, if you see your SMS, then 99% of the problem is in your service.`
      },
      {
        id: 3,
        title: `3. Do you have private numbers?`,
        question: `We have all the numbers is private. If you buy a number and receive SMS from any service/site, only you can use it for this service/site.`
      },
      {
        id: 4,
        title: `4. Do you have personal numbers?`,
        question: `No. All numbers are available to all clients. But, if you bought a number and verified for example "WhatsApp", then this number for "WhatsApp" will be available only to you. You have no reason to worry that anyone will steal your account.`
      },
      {
        id: 5,
        title: `5. Can I rent a number for a month, 3 months or a year?`,
        question: `We provide a 21+ day life guarantee for all numbers. You can see how many days of life the number has left on the page "Get SMS" in the tab "Step 3. Number".`
      },
      {
        id: 6,
        title: `6. Which means "21+ days"?`,
        question: `This means that your number will work for at least 21 days, but it can be for example 60 days or 180 days. To be sure that your number will not die, go to the site at least 21 days in advance and check your numbers.`
      },
      {
        id: 7,
        title: `7. Which means "Loyalty"?`,
        question: `Depending on how many loyalty points do you have, we will take a fee from you for unsuccessful orders (if you took a number and did not buy an SMS)`
      },
      {
        id: 8,
        title: `8. In what case should I use "Other" service?`,
        question: `If you didn't find the service that you need to verify, you should choose "Other" service, in this case, you can receive SMS from anywhere. But, keep in mind that we are constantly adding new services and advise you to search for your service first and if you can't find it, choose "Other".`
      },
      {
        id: 9,
        title: `9. Can I get a discount?`,
        question: `To everyone who wants big discounts, we have made especially for you an opportunity to get up to 50% discount.</br>
          With a €100 top-up, you get a €40 bonus.</br>
          With a €300 top-up, you get a €150 bonus.`
      }
    ]
  }),
  methods: {
    item(index) {
      alert(index);
    }
  }
};
</script>
